import React from "react"
import { Link } from "gatsby"

import styles from "./contributors.module.scss"
// import issue from "../../templates/athena/issue"

/*
  <Contributors
    zeus={article.zeusContributors}
    other={article.otherContributors}
    artwork={article.otherContributorsArtwork}
  />
*/


const defaultTitle = 'In collaboration with';

const Contributors = props => {

  if (!props.zeus.length && !props.other?.length && !props.artwork?.length) {
    return null
  }
  return (
  
    // byline is for reader view - not styling
    <address className={styles.contributors}>
      {props.zeus && (
        <div className={styles.contributorsZeus}>
          <div className={styles.contributorsTitle}>
            <h3 className="a-h-2">Contributors</h3>
          </div>
          <ul>
            {props.customContrib &&
              <li key={`zj-jordana`}>
                <a className="link-grow"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/jordana-rothman-91173a22//"
                    >Jordana Rothman</a>
              </li>
            }
            {props.zeus.map(person => (
              <li key={`zj-${person.id}`}>
                <Link className="link-grow" to={`/${person.uri}`}>
                  {person.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      {props.other && (
        <div className="text-center">
          <h3 className="mono body mb-2">{props.otherTitle ? (props.otherTitle) : (defaultTitle)}</h3>
          <ul>
            {props.other.map((person, index) => (
              <li key={`person-${index}`}>
                {person.website ? (
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={person.website}
                  >
                    {person.name}
                  </a>
                ) : (
                  <>{person.name}</>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {props.artwork && (
        <div className="text-center">
          <h3 className="mono body mb-2">Artwork By:</h3>
          <ul>
            {props.artwork.map((person, index) => (
              <li key={`person-${index}`}>
                {person.artistWebsite ? (
                  <a
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={person.artistWebsite}
                  >
                    {person.artistName}
                  </a>
                ) : (
                  <>{person.artistName}</>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </address>
  )
}

export default Contributors
